<template>
  <div>
    <Notification
      v-if="logoutReason"
      type="warning"
    >
      {{ logoutReason }}
    </Notification>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <div class="columns has-background-white">
      <div class="column">
        <h1 class="title is-size-5">{{ status }}</h1>
        <Loader
          v-if="submitting"
        />
        <p v-else>
          {{ message }}
        </p>
        <p class="subtitle is-size-6" style="margin-top:10px;">
          <a @click="go('/login')">Log back in</a>
        </p>
      </div>
      <div class="column has-background-info has-text-white" style="padding:40px;">
        <h1 class="title has-text-white"><Brand /></h1>
        <p class="subtitle has-text-white">Discover the best local deals</p>
      </div>
    </div>
  </div>
</template>

<script>
import {AuthError, NetworkError} from "@/errors/index.js";
import Brand from "@/components/Brand.vue";
import Notification from "@/components/Notification.vue";
import Loader from "@/components/Loader.vue";

export default {
  components: {
    Brand,
    Notification,
    Loader
  },
  data() {
    return {
      submitting: false,
      status: "",
      message: "",
      error: null,
    }
  },
  computed: {
    logoutReason() {
      let reason = ""
      switch (this.$route.query.r) {
        case "security":
          reason = "You have been logged out for security reasons."
          break
        case "deletion":
          reason = "You have been logged out because your account was deleted."
          break
      }
      return reason
    }
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.status = "Logging out..."
      this.submitting = true

      this.$store.dispatch("logout")
        .then(() => {
          this.status = "Logged out"
          this.message = "You have been successfully logged out. We look forward to seeing you again!"
          this.submitting = false

          if (window.NativeScript) {
            window.NativeScript.setLoginState(false);
          }
        })
        .catch(err => {
          this.status = "Logout failed"
          this.message = "Uh oh, we were unable to log you out!"
          this.submitting = false
 
          if (err instanceof AuthError) {
            this.error = err.message
          } else if (err instanceof NetworkError) {
            throw err
          } else {
            console.error(err)
            this.error = err
          }
        })
    }
  },
  methods: {
    go(path) {
      this.$router.push(path);
    }
  }
}
</script>
